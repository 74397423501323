import { translate } from '@/core/localization/translate';
export const {
  title,
  subtitle1,
  text1,
  subtitle2,
  text2,
  subtitle3,
  text3
} = translate({
  "title": "Der gr\xF6\xDFte Online-Shop in Europa f\xFCr Heimwerken, Haus & Garten",
  "subtitle1": "Millionen von Produkten",
  "text1": "Mehr Auswahl als in jedem Baumarkt und das gem\xFCtlich vom Sofa aus",
  "subtitle2": "Lieferungen nach Hause oder an eine Abholstation",
  "text2": "Sie entscheiden",
  "subtitle3": "Hunderte von Experten online",
  "text3": "Profis wie MacGyver \u2013 nur online"
});